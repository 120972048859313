import mountain from "../images/mountain.png";

export const Mountain = () => {

    return (
        <img
            src={mountain}
            className="mountain"
            alt="mountain and sunflower field"
        />
    )
}